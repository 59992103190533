<template>
  <v-carousel
    :cycle="false"
    class="mb-5 mt-12"
    light
    :hide-delimiters="isMobile"
    hide-delimiter-background
    :show-arrows="false"
    height="350px"
  >
    <v-carousel-item v-for="(chunk, index) in fornecedores" :key="index">
      <div class="d-flex">
        <v-row no-gutters class="">
        <template v-for="fornecedor in chunk">
            <v-col :cols="colSize" class="d-flex" :key="fornecedor.id">
              <px-card-fornecedor class="ma-3 fornecedor-slider-card"
                                  :fornecedor="fornecedor"/>
            </v-col>
        </template>
        </v-row>
      </div>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import PxCardFornecedor from '@/modules/home-page/PxCardFornecedor.vue';

export default {
  mixins: [gridMixin],
  props: {
    fornecedores: Array,
  },
  computed: {
    colSize() {
      if (this.isMobile) {
        return '12';
      }
      if (this.isSm) {
        return '6';
      }
      return '4';
    },
  },
  components: {
    PxCardFornecedor,
  },
};
</script>

<style scoped>
.fornecedor-slider-card {
  flex: 1 1 0 !important;
}
</style>
