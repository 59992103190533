<template>
  <div>
    <px-home-page-slide/>
    <px-home-page-categorias class="mt-5"/>
    <v-container class="mkt-content-container">
      <px-home-page-block v-if="isLogged"
                          class="mt-5"
                          query="disponivel is true"
                          :query-function="ofertaUltimasComprasApi"
                          title="Minhas últimas compras"
                          :qtd-ofertas="50"/>
      <px-home-page-block v-else
                          class="mt-5"
                          query="promocao.ativo is true and disponivel is true order by random"
                          title="Promoções"
                          :qtd-ofertas="100"/>
      <px-home-page-parceiros/>
      <px-home-page-block v-if="isLogged"
                          class="mt-5"
                          query="promocao.ativo is true and disponivel is true order by random"
                          title="Promoções"
                          :qtd-ofertas="100"/>
      <px-home-page-block-banner-duplo class="mt-10 mb-10"/>
      <px-home-page-block class="mt-5" query="disponivel is true order by createdIn desc"
                          title="Ofertas mais recentes"
                          :qtd-ofertas="30"/>
      <px-home-page-block-banner-secundario class="mt-10 mb-10"/>
      <px-landing-page-whatsapp :banner-cadastro-text-whats="bannerCadastroTextWhats"/>
      <px-home-page-block class="mt-5"
                          query="disponivel is true order by random"
                          title="Outras ofertas para você"
                          :qtd-ofertas="50"/>
      <px-home-page-block-banner-institucional class="mt-10 mb-10"/>
      <px-home-page-block class="mt-5"
                          query="destaque is true and disponivel is true order by random"
                          title="Ofertas em destaque"
                          :qtd-ofertas="50"/>
      <px-home-apoiadores class="mt-5"/>
      <px-rd-form-subscription class="mt-10"/>
      <px-survey v-if="isLogged" :user="user"/>
    </v-container>
  </div>
</template>

<script>
import HomePageBlockBannerDuplo from '@/modules/home-page/HomePageBlockBannerDuplo.vue';
import HomePageBlockBannerSecundario from '@/modules/home-page/HomePageBlockBannerSecundario.vue';
import HomePageBlockBannerInstitucional from '@/modules/home-page/HomePageBlockBannerInstitucional.vue';
import OfertaService from '@/modules/ofertas/oferta-service';
import { mapGetters } from 'vuex';
import HomePageBlock from './HomePageBlock.vue';
import HomePageSlide from './HomePageSlide.vue';
import HomePageCategorias from './HomePageCategorias.vue';
import RdFormSubscription from '../integration/rd/RdFormSubscription.vue';
import HomePageParceiros from './HomePageParceiros.vue';
import LandingPageWhatsapp from '../landing-page/LandingPageWhatsapp.vue';
import HomeApoiadores from './HomeApoiadores.vue';

export default {
  data() {
    return {
      ofertaUltimasComprasApi: OfertaService.getUltimasCompras,
      bannerCadastroTextWhats: 'Dúvida? Fale com nosso time! Será um prazer te ajudar!',
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
    ...mapGetters('user', ['user']),
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxHomePageSlide: HomePageSlide,
    pxHomePageCategorias: HomePageCategorias,
    pxHomePageBlockBannerDuplo: HomePageBlockBannerDuplo,
    pxHomePageBlockBannerSecundario: HomePageBlockBannerSecundario,
    pxHomePageBlockBannerInstitucional: HomePageBlockBannerInstitucional,
    pxRdFormSubscription: RdFormSubscription,
    pxHomePageParceiros: HomePageParceiros,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
    pxHomeApoiadores: HomeApoiadores,
  },
};
</script>
