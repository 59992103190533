<template>
  <div>
    <px-loading-horizontal-cards :loading="loading"/>
    <div v-if="!loading && fornecedores.content && fornecedores.content.length">
      <v-row>
        <v-col cols="12">
          <span class="headline font-weight-bold">
            Lojas oficiais
          </span>
          <px-fornecedor-carousel :fornecedores="fornecedoresChunks"/>
        </v-col>
      </v-row>
      <v-row no-gutters class="caption text--primary--color">
        <v-col cols="12">
          Quer sua marca em nosso site?
          <a :href="hrefLinkSaberMais" target="_blank">
            Clique aqui!
          </a>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { chunk } from 'lodash';
import FornecedorService from '@/api/fornecedor-marketplace-service';
import gridMixin from '@/mixins/grid-mixin';
import FornecedorCarousel from '@/modules/home-page/FornecedorCarousel.vue';

const SLIDE_CHUNK = 6;
const SLIDE_CHUNK_SM = 4;
const SLIDE_CHUNK_MOBILE = 2;
const LIMIT_LOGOS = 100;

export default {
  mixins: [gridMixin],
  data() {
    return {
      slideState: null,
      fornecedoresChunks: [],
      loading: false,
      hrefLinkSaberMais: 'https://api.whatsapp.com/send?phone=554891446585&text=Ol%C3%A1!%20Quero%20saber%20como%20colocar%20minha%20marca%20no%20Marketplace%20Proxpect!',
      fornecedores: {
        content: [],
        total: 0,
      },
    };
  },
  methods: {
    buildFornecedoresChunks(fornecedores) {
      let chunkSize = SLIDE_CHUNK;
      if (this.isMobile) {
        chunkSize = SLIDE_CHUNK_MOBILE;
      }
      if (this.isSm) {
        chunkSize = SLIDE_CHUNK_SM;
      }
      this.fornecedoresChunks = chunk(fornecedores, chunkSize);
    },
    getFornecedores() {
      this.loading = true;
      FornecedorService.getFornecedoresParceiros({
        query: 'order by random',
        limit: LIMIT_LOGOS,
      })
        .then(({ data }) => {
          this.fornecedores = data;
          this.buildFornecedoresChunks(this.fornecedores.content);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.getFornecedores();
  },
  components: {
    pxFornecedorCarousel: FornecedorCarousel,
  },
};
</script>
