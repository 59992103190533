<template>
  <div id="rd-form-subscription">
    <div role="main" :id="rdFormId"></div>
  </div>
</template>

<script>
import rdLoader from '@/modules/integration/rd/rd-loader';
import { mapGetters } from 'vuex';
import { RD_FORM_ID, RD_GA_CODE } from './rd-variables';

export default {
  data() {
    return {
      rdFormId: RD_FORM_ID,
    };
  },
  computed: {
    ...mapGetters(['isLogged']),
  },
  methods: {
    loadScript() {
      rdLoader(this.loadForm);
    },
    loadForm() {
      // eslint-disable-next-line no-undef
      new RDStationForms(RD_FORM_ID, RD_GA_CODE)
        .createForm();
    },
  },
  mounted() {
    if (this.isLogged) {
      return;
    }
    this.loadScript();
  },
};
</script>
