import { RD_FORM_SCRIPT } from '@/modules/integration/rd/rd-variables';

export default function (callback) {
  // eslint-disable-next-line no-undef
  if (typeof RDStationForms === 'undefined') {
    const script = document.createElement('script');
    script.onload = callback;
    script.src = RD_FORM_SCRIPT;
    document.body.appendChild(script);
    return;
  }
  callback();
}
