<template>
  <v-row dense no-gutters>
    <v-col cols="6" class="mt-3" v-for="(banner, index) in banners" :key="index">
      <px-home-banner-card :title="banner.titulo"
                           :link="banner.link"
                           :gradient="banner.gradient"
                           :banner-image="banner.foto"
                           :max-height="height"
                           :min-height="height"/>
    </v-col>
  </v-row>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomeBannerCard from '@/modules/home-page/HomeBannerCard.vue';
import imgParaComprador from '../../assets/area-comprador.png';
import imgParaCompradorMobile from '../../assets/comprador-mobile.png';
import imgParaFornecedor from '../../assets/area-fornecedor.png';
import imgParaFornecedorMobile from '../../assets/fornecedor-mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      banners: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '180px';
      }
      return '360px';
    },
  },
  created() {
    this.banners = [
      {
        link: '/para-empresas',
        foto: this.getGridImg(imgParaComprador, imgParaCompradorMobile),
        gradient: 'none',
      },
      {
        link: '/para-fornecedores',
        foto: this.getGridImg(imgParaFornecedor, imgParaFornecedorMobile),
        gradient: 'none',
      },
    ];
  },
  components: {
    pxHomeBannerCard: HomeBannerCard,
  },
};
</script>
